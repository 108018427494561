<template>
  <content-items-view
    content-type="files"
    content-type-singular="file"
    content-name="Files"
    content-name-singular="File"
  />
</template>

<script>
import ContentItemsView from '@/views/content-item/content-items-view/ContentItemsView.vue'

export default {
  components: {
    ContentItemsView,
  },
}
</script>

<style scoped>

</style>
